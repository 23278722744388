import React  from "react";
import { ResponsiveLineCanvas } from "@nivo/line";
import { SectionHeader } from "./section-header"; 

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default class Linechart extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ticks: 10 };     
  }

  componentDidMount() {
    if (typeof window === "undefined") return;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    if (window.innerWidth <= 600) {
      this.setState({ ticks: 6, size: 'sm'});
    } else if (window.innerWidth > 600 && window.innerWidth <= 896) {
      this.setState({ ticks: 10, size: 'md' });
    } else {
      this.setState({ ticks: 20, size: 'lg'});
    }
  }

  getXScale(props) {
    if(props.xScale === 'time') {
      return {
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day',        
      }
    } else {
      return { type: "linear", min: "auto" };      
    }
  }

  getAxisBottom(props, state) {

    let commonProps = {
      orient: "bottom",
      tickSize: 5,
      tickValues: state.ticks,        
      legendOffset: 35,
      legendPosition: "middle"     
    }

    if(props.xAxis === 'monthly') {
      return {
        ...commonProps,
        legend: "Months in Office"        
      }
    } else if(props.xAxis === 'quarterly') {
      return {
        ...commonProps,
        legend: "Quarters in Office"
      }
    } else {
      return {
        ...commonProps,
        legend: "Days In Office"        
      }; 
    }
  }
  render() {
    let chartHeight = 600; 

    if(this.state.size === 'sm') {
      chartHeight = 460;
    }

    if(this.state.size === 'md') {
      chartHeight = 520; 
    }

    return (          

      <div>
        { (this.props.title) ? <SectionHeader name={this.props.title} /> : "" }  
        <div style={{ backgroundColor: "white", textAlign: "center", width: "100%", height: chartHeight}}>
        <ResponsiveLineCanvas
          data={this.props.chartData}          
          margin={{ top: 40, right: 10, bottom: 70, left: 50 }}
          xScale={ this.getXScale(this.props) }      
          yScale={{
            type: "linear",
            min: this.props.yScaleMin || -80,
            max: "auto",
            stacked: false,
            reverse: false
          }}
          curve="monotoneX"
          animate={false}
          axisBottom={ this.getAxisBottom(this.props, this.state)}
          axisLeft={{
            orient: "bottom",
            tickSize: 5,
            tickValues: 14,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Percent Gain",
            legendOffset: -35,
            legendPosition: "middle"
          }}
          enableGridX={true}
          enableGridY={true}
          colors={{ scheme: "category10" }}
          enablePoints={false}
          useMesh={true}          
          theme={{
            legends: {
              text: {
                fontSize: 13,
              },              
            }
          }}
          crosshairType="cross"
          isInteractive={true}          
          tooltip={
            (hover) => { 
              let xString = (this.props.xLabel) ? this.props.xLabel : 'x'; 
              let yString = (this.props.yLabel) ? this.props.yLabel : 'y';
              const point = hover.point;                     
              return (
               <div style={{ backgroundColor: "#ffffff", border: "1px solid #333", borderRadius: "5px", padding: "5px" }}>
                 <b style={{color: point.serieColor}}>{point.serieId}</b><br />
                 {xString}: { point.data.x } <br />
                 {yString}: {point.data.y.toFixed(2)}%
              </div>)}
          }
          enableSlices={false}
          legends={[
            {            
              anchor: "top",
              direction: "row",              
              translateX: 0,
              translateY: -28,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 130,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 16,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",              
            }
          ]}
        />
        </div>
      </div>
    );
  }
}

