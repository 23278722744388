/**
 * Of the list of president the one them is listed as the current president
 * This is useful throughout the site as we perform different operations if the presidnet 
 * is a current president vs previous president. 
 * 
 * @param integer[] 
 * @return boolean
 */
export function isCurrentPres(presidents = []) {
  if(!typeof presidents === "Array") {
    throw new TypeError('isCurrentPres expects an array of president numbers'); 
  }

  return (presidents.indexOf(process.env.GATSBY_CURRENT_PRESIDENT) !== -1);
} 

/**
 * Get the current president index from a president list
 * @param integer[] presidents 
 */
export function currentPres(presidents = []) {
  if(!typeof presidents === "Array") {
    throw new TypeError('currentPres expects an array of president numbers'); 
  }
  return presidents.indexOf(process.env.GATSBY_CURRENT_PRESIDENT); 
}