/*eslint no-sequences: "off"*/

import React, { Component } from "react";
import Helmet from "react-helmet"; 
import { graphql } from "gatsby";
import Loader from 'react-loader-spinner'; 
import moment from 'moment'; 

import { lastname, lastnameImage, percent, difference, metricName } from "../utils/formatters";
import { isCurrentPres, currentPres } from "../utils/president-data"; 
import { presidentSlug } from "../utils/slugs"; 
import Linechart from "../components/common/Linechart"; 
import Section from "../components/common/Section"; 
import SEO from "../components/common/SEO"; 


// Common components & Layout
import Layout from "../layouts";
import { Table } from "react-bootstrap";
import Newsletter from "../components/newsletter/newsletter";
import SocialMediaLink from "../components/common/SocialMediaLink"; 

let allChartData = {}; 
( async () =>  {

  // @todo replace 45 with max president variable from global config
  for(let presnum=33; presnum <= process.env.GATSBY_CURRENT_PRESIDENT; presnum++) {
    for(let i=2; i < 5; i++) {
      if(!allChartData[presnum]) { allChartData[presnum] = {} };  

        try {
          allChartData[presnum][i] = require(`../../static/data/president-${presnum}-${i}.json`);          
        } catch (err) {          
          allChartData[presnum][i] = []; 
          console.error(` The following data file was not found '../../static/data/president-${presnum}-${i}.json' `);           
        }            
    }

    allChartData[presnum][1] = require(`../../static/data/president-${presnum}-1.json`);   
    allChartData[presnum][17] = require(`../../static/data/president-${presnum}-17.json`);       
  }
})(); 

/**
 * This will create the president opening text which changes based on whether a president is active or not. 
 * 
 * @param {President} pres1 
 * @param {President} pres2 
 * @return React.Fragment
 */
function getIntroText(pres1, pres2) {

   if(isCurrentPres([pres1.presidentId, pres2.presidentId])) {

    const current = (currentPres([pres1.presidentId, pres2.presidentId]) === 0) ? pres1 : pres2; 
    const other = (currentPres([pres1.presidentId, pres2.presidentId]) === 0) ? pres2 : pres1; 

    return (
      <React.Fragment>
        <span>        
            Since President { lastname(current.name) } is still in office, {lastname(current.name)}'s 
             performance is still current date and { lastname(other.name) }'s performance
                is for the same equivalent time period in { lastname(other.name)}'s presidency.
        </span>        
      </React.Fragment>
    )
  } else {

    return (
      <React.Fragment>
        <span>
          President { lastname(pres1.name) } and President { lastname(pres2.name) } data are no longer in office.  Their data 
          has been set to be as equivalent by days in office to make a fair comparison of their performance.  This accounts 
          for presidents that had one term vs two terms, or presidents that had a much shortened term. 
        </span>
      </React.Fragment>
    )

  }
}

/**
 * In order to have a fair comparison we need to compare presidents by the closest
 * metrics based on their impact.  The description below highlights the rules for how 
 * we compare presidents. 
 * 
 * Case 1:
 *  - Current president compared against previous 2 term president
 *  Output: Use current day number on both (as close as  possible)
 * Case 2:
 *  - 2 term president against previous 1 term president (this also works for current president)
 *  Output: Use Term 1 close for 2 term president and total for 1 term president
 * Case 3:
 *  - Current president under before term 2
 *  Output: Use current day match up
 * 
 * @param {President} pres1 
 * @param {President} pres2 
 */
function buildCompare(pres1, pres2) {

  let compareModel = {
    pres1: {
      dow_perc: null, 
      nasdaq_perc: null,
      sandp_perc: null,
      gdp_total: null,
      payroll_total: null
    },
    pres2: {
      dow_perc: null, 
      nasdaq_perc: null,
      sandp_perc: null,
      gdp_total: null,
      payroll_total: null
    }
  };

  
  if(isCurrentPres([pres1.presidentId, pres2.presidentId])) {  

    compareModel.pres1 = {
      dow_perc: pres1.dow_today_percent ? pres1.dow_today_percent : pres1.dow_total, 
      nasdaq_perc: pres1.nasdaq_today_percent ? pres1.nasdaq_today_percent : pres1.nasdaq_total,
      sandp_perc: pres1.sandp_today_percent ? pres1.sandp_today_percent : pres1.sandp_total,
      gdp_total: pres1.gdp_today_percent ? pres1.gdp_today_percent : pres1.gdp_total,  
      payroll_total: pres1.payroll_today_percent ? pres1.payroll_today_percent : pres1.payroll_total, 
      dow_perc_ann: pres1.dow_today_annualized ? pres1.dow_today_annualized : pres1.dow_annualized,
      nasdaq_perc_ann: pres1.nasdaq_today_annualized ? pres1.nasdaq_today_annualized : pres1.nasdaq_annualized,
      sandp_perc_ann: pres1.sandp_today_annualized ? pres1.sandp_today_annualized : pres1.sandp_annualized,
      gdp_ann: pres1.gdp_today_annualized ? pres1.gdp_today_annualized : pres1.gdp_annualized,
      payroll_ann: pres1.payroll_today_annualized ? pres1.payroll_today_annualized : pres1.payroll_annualized
    };

    compareModel.pres2 = {
      dow_perc: pres2.dow_today_percent ? pres2.dow_today_percent : pres2.dow_total, 
      nasdaq_perc: pres2.nasdaq_today_percent ? pres2.nasdaq_today_percent : pres2.nasdaq_total,
      sandp_perc: pres2.sandp_today_percent ? pres2.sandp_today_percent : pres2.sandp_total,
      gdp_total: pres2.gdp_today_percent ? pres2.gdp_today_percent : pres2.gdp_total,  
      payroll_total: pres2.payroll_today_percent ? pres2.payroll_today_percent : pres2.payroll_total, 
      dow_perc_ann: pres2.dow_today_annualized ? pres2.dow_today_annualized : pres2.dow_annualized,
      nasdaq_perc_ann: pres2.nasdaq_today_annualized ? pres2.nasdaq_today_annualized : pres2.nasdaq_annualized,
      sandp_perc_ann: pres2.sandp_today_annualized ? pres2.sandp_today_annualized : pres2.sandp_annualized,
      gdp_ann: pres2.gdp_today_annualized ? pres2.gdp_today_annualized : pres2.gdp_annualized,
      payroll_ann: pres2.payroll_today_annualized ? pres2.payroll_today_annualized : pres2.payroll_annualized
    };
    
  } else {

    compareModel.pres1 = {
      dow_perc: pres1.dow_total, 
      nasdaq_perc: pres1.nasdaq_total,
      sandp_perc: pres1.sandp_total,
      gdp_total: pres1.gdp_total, 
      payroll_total: pres1.payroll_total, 
      dow_perc_ann: pres1.dow_annualized,
      nasdaq_perc_ann: pres1.nasdaq_annualized,
      sandp_perc_ann: pres1.sandp_annualized,
      gdp_ann: pres1.gdp_annualized,
      payroll_ann: pres1.payroll_annualized
  
    }; 
  
    compareModel.pres2 = {
      dow_perc: pres2.dow_total,
      nasdaq_perc: pres2.nasdaq_total,
      sandp_perc: pres2.sandp_total,
      gdp_total: pres2.gdp_total,
      payroll_total: pres2.payroll_total,
      dow_perc_ann: pres2.dow_annualized, 
      nasdaq_perc_ann: pres2.nasdaq_annualized, 
      sandp_perc_ann: pres2.sandp_annualized,
      gdp_ann: pres2.gdp_annualized,
      payroll_ann: pres2.payroll_annualized
    };

  }
  
  // add some convinence data about the comparison president data that can be used for styling the page
  for(const metric in compareModel.pres1) {
    let diffName = `${metric}_diff`;     
    compareModel[diffName] = compareModel.pres1[metric] - compareModel.pres2[metric]; 

    if(!compareModel.pres1[metric] || !compareModel.pres2[metric]) {
      compareModel[`${metric}_css`] = ''; 
    } else {
      if(compareModel[diffName] > 0) {
        compareModel[`${metric}_css`] = 'positive'; 
      } else {
        compareModel[`${metric}_css`] = 'negative'; 
      }
    }  
  }

  return compareModel; 
}

/**
 * This is highlevel analysis for SEO purposes across the top 5 metrics we track 
 * 
 * @param {President} pres1 
 * @param {President} pres2 
 * @return React.Fragment
 */
function getAnalysisText(pres1, pres2) {

  const compareModel = buildCompare(pres1, pres2); 
  let metricBetter = 0; 
  let metricBetterName = ''; 
  let metricWorseName = '';
  let metricWorse = 10000;
  let areBetter = 0;  
  let metrics = [
    "dow_perc",
    "nasdaq_perc",
    "sandp_perc", 
    "gdp_total", 
    "payroll_total"
  ]; 

  metrics.forEach( function(item) {
    if(compareModel.pres1[item] > compareModel.pres2[item]) {  
      metrics++; 
    }

    let metricDiff = (compareModel.pres1[item] - compareModel.pres2[item]);

    if(metricDiff > 0) {
      areBetter++;
    }

    if(metricDiff > metricBetter) {
      metricBetter = metricDiff; 
      metricBetterName = metricName(item);       
    } 

    if(metricDiff < metricWorse) {
      metricWorse = metricDiff; 
      metricWorseName = metricName(item); 
    }     
  }); 

  return (
    <React.Fragment>
    <span>
      With respect to cumulative performance, {pres1.name} has performed better on <b>{areBetter} out of the 5</b> metrics we track.&nbsp;
      
      { areBetter > 0 && 
       `  President ${lastname(pres1.name)}'s 
       highest performance difference was on the indicator ${metricBetterName} performing ${percent(metricBetter)} better 
       than President ${lastname(pres2.name)}. 
       `
      }
      
       President {lastname(pres1.name)}'s lowest performance difference was on {metricWorseName} performing {percent(metricWorse)} 
      {
        metricWorse < 0 ? ` worse than President ${lastname(pres2.name)}.` : ` better than President ${lastname(pres2.name)}.`
      }
    </span>
    </React.Fragment>
  ); 
    
}

function getDiffBox(name, comparison, metric) {
  if(!comparison.pres1[metric] || !comparison.pres2[metric]) {
    return 'N/A'; 
  } else {
    return `${lastname(name)} ${ difference(comparison.pres1[metric], comparison.pres2[metric], true)}`;
  }
}

class ComparisonPage extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      hideStockCharts: true,
      hideOtherCharts: true,
      chartData: {
        nasdaq: [],
        dow: [],
        sandp: []
      } 
    };

  }

  componentDidMount() {
    const president = this.props.data.allPresidentSummary.nodes;
    let pres1, pres2; 

    if(this.props.pageContext.pres1 !== president[0].presidentId) { 
      pres1 = president[1]; 
      pres2 = president[0]; 
    } else {
      pres1 = president[0]; 
      pres2 = president[1]; 
    }

    let chartData = {};

    try {
      chartData.nasdaq = allChartData[+pres1.presidentId][2].concat(
        allChartData[+pres2.presidentId][2]
      );

      chartData.dow = allChartData[+pres1.presidentId][3].concat(
        allChartData[+pres2.presidentId][3]
      );

      chartData.sandp = allChartData[+pres1.presidentId][4].concat(
        allChartData[+pres2.presidentId][4]
      );

      chartData.gdp = allChartData[+pres1.presidentId][1].concat(
        allChartData[+pres2.presidentId][1]
      );

      chartData.payroll = allChartData[+pres1.presidentId][17].concat(
        allChartData[+pres2.presidentId][17]
      );               
    } catch (e) {
      console.error(e.message);       
    } 
    
    this.setState({ chartData: chartData}); 

    setTimeout(() => { this.setState({ hideStockCharts: false })}, 500);
    setTimeout(() => { this.setState({ hideOtherCharts: false })}, 1000);
  }

  render() {    
    const president = this.props.data.allPresidentSummary.nodes;

    let lastUpdated; 

    for(let item of this.props.data.allLatestMetrics.edges) {
       if(item.node.name === 'dow') {
        lastUpdated = moment(item.node.date).utcOffset(6).format('MMMM Do, YYYY');        
       }
    }    

    let president1, president2; 
    // since graphql results are not in order of url, we need to look at which president was selected first 
    if(this.props.pageContext.pres1 !== president[0].presidentId) { 
      president1 = president[1]; 
      president2 = president[0]; 
    } else {
      president1 = president[0]; 
      president2 = president[1]; 
    }

    this.chartData = this.state.chartData; 

    if(!president1 || !president2) {
      console.error('Presidents comparison page could not be generated');
      return (
        <Layout> Broken Page </Layout>        
     ); 
    }

    const comparison = buildCompare(president1, president2); 

    president1.name = (president1.name) ? president1.name : "unknown";
    president2.name = (president2.name) ? president2.name : "unknown";

    let shareStockText = `Compare ${president1.name} and ${president2.name} to see how they stack up on stock market performance, jobs and GDP growth`;

       return (
      
      <Layout>
      	<Helmet bodyAttributes={{ class: "comparison" }} />
        <SEO post={{
           title: `${president1.name} vs ${president2.name} Stock Market, GDP, and Jobs Created`,
           description: `Compare ${president1.name} and ${president2.name} to see how they stack up on stock market performance, jobs and GDP growth`,
           imageLink: `compare-${lastnameImage(president1.name)}-${lastnameImage(president2.name)}.jpg`,
           path: `comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`
        }} />
        <div id="hero" className="orange">                         
          <div className={`hero-text compare-left-portrait ${lastnameImage(president1.name)}`}>
            
            <cite>Presidential Comparison</cite>
            <h2>{ lastname(president1.name) } vs { lastname(president2.name) }</h2>
            <span className={`compare-right-portrait ${lastnameImage(president2.name)}`}></span>  
          </div>
        </div>
        <main>
          <section id="stock-market-performance">          
            <div className="container intro">
            <p style={{fontStyle:'italic'}}>Last updated: {lastUpdated}</p>
              <p>
                This page has the comparisons between { lastname(president1.name)} vs { lastname(president2.name) } across all
                the metrics we track. { getIntroText(president1, president2) }
              </p>
              <p>
               { getAnalysisText(president1, president2) }
              </p>
              <p>
                We highly recommend you read this article as well prior: <a href="/whats-the-best-way-to-interpret-the-facts-and-data/">What's
                the best way to interpret the facts and data on Facts First?</a>
              </p>
            </div>
            <div className="section-hed">
              { lastname(president1.name)} vs. { lastname(president2.name) } stock market performance              
            </div>
            <div className="container">           
            <p> 
              How does {lastname(president1.name)} compare to {lastname(president2.name)} in the stock market? We share the facts on cumulative and annualized performance between {lastname(president1.name)} and {lastname(president2.name)} in the stock market. Cumulatively across the S&P 500, {lastname(president1.name)} is at <b>{percent(comparison.pres1.sandp_perc)}</b> compared to {lastname(president2.name)} at <b>{percent(comparison.pres2.sandp_perc)}</b> a difference of <b>{difference(comparison.pres1.sandp_perc, comparison.pres2.sandp_perc)}</b>. On the NASDAQ, {lastname(president1.name)} is at <b>{percent(comparison.pres1.nasdaq_perc)}</b> compared to {lastname(president2.name)} at <b>{percent(comparison.pres2.nasdaq_perc)}</b>
               &nbsp; a difference of <b>{difference(comparison.pres1.nasdaq_perc, comparison.pres2.nasdaq_perc)}</b>. Finally, on the DOW Jones, {lastname(president1.name)} is at <b>{percent(comparison.pres1.dow_perc)}</b> compared to {lastname(president2.name)} at <b>{percent(comparison.pres2.dow_perc)}</b> a 
               difference of <b>{difference(comparison.pres1.dow_perc, comparison.pres2.dow_perc)}.</b>               
              </p>          
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th width="30%">Index<br/>Cumulative Performance</th>
                    <th width="20%">{president1.name}</th>                                    
                    <th width="20%">{president2.name}</th>                                    
                    <th width="30%">Difference</th>                                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NASDAQ</td>
                    <td>{percent(comparison.pres1.nasdaq_perc)}</td>                    
                    <td>{percent(comparison.pres2.nasdaq_perc)}</td>                    
                    <td className={comparison.nasdaq_perc_css}>{getDiffBox(president1.name, comparison, 'nasdaq_perc')}</td>                    
                  </tr>
                  <tr>
                    <td>DOW</td>
                    <td>{percent(comparison.pres1.dow_perc)}</td>                    
                    <td>{percent(comparison.pres2.dow_perc)}</td>                    
                    <td className={comparison.dow_perc_css}>{getDiffBox(president1.name, comparison, 'dow_perc')}</td>    
                  </tr>
                  <tr>
                    <td>S&P</td>
                    <td>{percent(comparison.pres1.sandp_perc)}</td>                    
                    <td>{percent(comparison.pres2.sandp_perc)}</td>                    
                    <td className={comparison.sandp_perc_css}>{getDiffBox(president1.name, comparison, 'sandp_perc')}</td>    
                  </tr>                    
                </tbody>
              </Table>                
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th width="30%">Index<br/>Annualized Performance</th>
                    <th width="20%">{president1.name}</th>                                    
                    <th width="20%">{president2.name}</th>                                    
                    <th width="30%">Difference</th>                                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NASDAQ</td>
                    <td>{percent(comparison.pres1.nasdaq_perc_ann)}</td>                    
                    <td>{percent(comparison.pres2.nasdaq_perc_ann)}</td>                    
                    <td className={comparison.nasdaq_perc_ann_css}>{getDiffBox(president1.name, comparison, 'nasdaq_perc_ann')}</td>                    
                  </tr>
                  <tr>
                    <td>DOW</td>
                    <td>{percent(comparison.pres1.dow_perc_ann)}</td>                    
                    <td>{percent(comparison.pres2.dow_perc_ann)}</td>                    
                    <td className={comparison.dow_perc_ann_css}>{getDiffBox(president1.name, comparison, 'dow_perc_ann')}</td>    
                  </tr>
                  <tr>
                    <td>S&P</td>
                    <td>{percent(comparison.pres1.sandp_perc_ann)}</td>                    
                    <td>{percent(comparison.pres2.sandp_perc_ann)}</td>                    
                    <td className={comparison.sandp_perc_ann_css}>{getDiffBox(president1.name, comparison, 'sandp_perc_ann')}</td>    
                  </tr>                    
                </tbody>
              </Table>                             
              <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />              
              <div hidden={!this.state.hideStockCharts} style={{ height: "400px", position: "relative", border: "1px solid #e1e1e1"}}>
                <Loader type="Bars" color="#444444" height={80} width={80} style={{  position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} />
              </div>
                <div hidden={this.state.hideStockCharts}>
                  <Linechart chartData={this.chartData.nasdaq} 
                  title="NASDAQ Comparison Chart"                    
                    yScaleMin="auto"                    
                    xLabel="Day"
                    yLabel="Gain/Loss" />  
                    
                    
                </div>                
                <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />              
              
              <div hidden={!this.state.hideStockCharts} style={{ height: "400px", position: "relative", border: "1px solid #e1e1e1"}}>
                <Loader type="Bars" color="#444444" height={80} width={80} style={{  position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} />
              </div>
                <div hidden={this.state.hideStockCharts}>
                  <Linechart chartData={this.chartData.dow} title="DOW JONES Comparison Chart"
                  yScaleMin="auto"                    
                  xLabel="Day"
                  yLabel="Gain/Loss"
                  />                    
                </div>                
                <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />              
              
              <div hidden={!this.state.hideStockCharts} style={{ height: "400px", position: "relative", border: "1px solid #e1e1e1"}}>
                  <Loader type="Bars" color="#444444" height={80} width={80} style={{  position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} />
              </div>                  
              <div hidden={this.state.hideStockCharts}>
                <Linechart chartData={this.chartData.sandp} title="S & P Comparison Chart"
                yScaleMin="auto"                    
                xLabel="Day"
                yLabel="Gain/Loss"
                />                   
              </div>              
              <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />                          
            </div>
          </section>
          <section id="stock-market-performance">
            <div className="section-hed"> { lastname(president1.name)} vs. { lastname(president2.name) } GDP performance</div>
            <div className="container">
              <p> How does {lastname(president1.name)} compare to {lastname(president2.name)} with respect to GDP Growth? We share the facts on cumulative
              and annualized GDP growth. Cumulatively {lastname(president1.name)} is at <b>{percent(comparison.pres1.gdp_total)}</b> compared to {lastname(president2.name)} at <b>
               {percent(comparison.pres2.gdp_total)}</b> a difference of <b>{difference(comparison.pres1.gdp_total, comparison.pres2.gdp_total)}</b>
              </p>
            <div className="chart">
                  <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>GDP<br/></th>
                      <th>{president1.name}</th>                                    
                      <th>{president2.name}</th>                                    
                      <th>Difference</th>                                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cumulative</td>
                      <td>{percent(comparison.pres1.gdp_total)}</td>                    
                      <td>{percent(comparison.pres2.gdp_total)}</td>                    
                      <td className={comparison.gdp_total_css}>{getDiffBox(president1.name, comparison, 'gdp_total')}</td>                    
                    </tr>
                    <tr>
                      <td>Annualized</td>
                      <td>{percent(comparison.pres1.gdp_ann)}</td>                    
                      <td>{percent(comparison.pres2.gdp_ann)}</td>                    
                      <td className={comparison.gdp_ann_css}>
                      {getDiffBox(president1.name, comparison, 'gdp_ann')}
                        </td>                    
                    </tr>                   
                  </tbody>
                </Table>                  
                <br />
                <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />                                             
                <div hidden={!this.state.hideOtherCharts} style={{ height: "400px", position: "relative", border: "1px solid #e1e1e1"}}>
                    <Loader type="Bars" color="#444444" height={80} width={80} style={{  position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} />
                </div>
                <div hidden={this.state.hideOtherCharts}>
                  <Linechart chartData={this.chartData.gdp} title="GDP Growth Chart"
                  yScaleMin="auto"                    
                  xLabel="Quarter"
                  yLabel="GDP Growth" 
                  xAxis="quarterly"
                  />                   
                </div>                
                <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />              
                
              </div>                             
            </div>
          </section>
          <section id="job-growth-performance">
            <div className="section-hed">
            { lastname(president1.name)} vs. { lastname(president2.name) } jobs growth performance
            </div>
            <div className="container">         
            <p> How does {lastname(president1.name)} compare to {lastname(president2.name)} in non-farm payroll? We share the facts on cumulative and annualized jobs created. Cumulatively {lastname(president1.name)} is at <b>{percent(comparison.pres1.payroll_total)}</b> compared to {lastname(president2.name)} at <b>
               {percent(comparison.pres2.payroll_total)}</b> a difference of <b>{difference(comparison.pres1.payroll_total, comparison.pres2.payroll_total)}</b>
              </p>
            <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Indicator <br/>Cumulative Gain/Loss</th>
                      <th>{president1.name}</th>                                    
                      <th>{president2.name}</th>                                    
                      <th>Difference</th>                                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cumulative</td>
                      <td>{percent(comparison.pres1.payroll_total)}</td>                    
                      <td>{percent(comparison.pres2.payroll_total)}</td>                    
                      <td className={comparison.payroll_total_css}>
                      {getDiffBox(president1.name, comparison, 'payroll_total')}
                        </td>                    
                    </tr>    
                    <tr>
                      <td>Annualized</td>
                      <td>{percent(comparison.pres1.payroll_ann)}</td>                    
                      <td>{percent(comparison.pres2.payroll_ann)}</td>                             
                      <td className={comparison.payroll_ann_css}>
                      {getDiffBox(president1.name, comparison, 'payroll_ann')}             
                        </td>                    
                    </tr>                                   
                  </tbody>
                </Table>                               
                <br />
                <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />         
                <div hidden={!this.state.hideOtherCharts} style={{ height: "400px", position: "relative", border: "1px solid #e1e1e1"}}>
                    <Loader type="Bars" color="#444444" height={80} width={80} style={{  position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} />
                </div>
                <div hidden={this.state.hideOtherCharts}>
                  <Linechart chartData={this.chartData.payroll} title="Job Growth Chart" 
                  yScaleMin="auto"                    
                  xLabel="Month"
                  yLabel="Job Growth"
                  xAxis="monthly"
                  />                  
                </div>  
                <br />
              <SocialMediaLink text={shareStockText} url={`comparison/${presidentSlug(president1.name)}/${presidentSlug(president2.name)}`} />                                                    
            </div>
          </section>          
          <Section
                name="metrics_calculation"
                header={`How do we calculate data for  ${president1.name.split(" ").pop()} vs. ${president2.name.split(" ").pop()}?`}
              />
        </main>
        <br />        
        <Newsletter />
        <br />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($pres1: String!, $pres2: String!) {
    allPresidentSummary(filter : {presidentId: { in: [$pres1, $pres2] }}) {
      nodes {
        id
        dataSummary
        gdpSummary
        jobSummary
        keyEvents
        name
        keyPolicies
        party
        stockSummary
        summary
        term
        presidentId
        dow_1_perc
        dow_2_perc
        dow_1_total
        dow_2_total
        dow_1_annualized
        dow_2_annualized
        sandp_2_perc
        sandp_1_perc
        sandp_1_total
        sandp_2_total
        sandp_1_annualized
        sandp_2_annualized
        payroll_1_perc
        payroll_2_perc
        payroll_1_total
        payroll_2_total
        payroll_1_annualized
        payroll_2_annualized
        gdp_1_perc
        gdp_2_perc
        gdp_1_total
        gdp_2_total
        gdp_1_annualized
        gdp_2_annualized
        nasdaq_1_perc
        nasdaq_2_perc
        nasdaq_1_total
        nasdaq_2_total
        nasdaq_1_annualized
        nasdaq_2_annualized
        dow_today_percent
        dow_today_annualized
        dow_annualized
        dow_total
        sandp_today_percent
        sandp_today_annualized
        sandp_annualized
        sandp_total
        payroll_total
        payroll_annualized
        payroll_today_percent
        payroll_today_annualized
        nasdaq_today_percent
        nasdaq_today_annualized
        nasdaq_total
        nasdaq_annualized
        gdp_total        
        gdp_annualized
        gdp_today_percent
        gdp_today_annualized
      }
    }
    allLatestMetrics {
      edges {
        node {
          id
          date
          name
          todayTotalGain
          percentGain
        }
      }
    }
  }
`;

export default ComparisonPage;
